import React from "react";
// import classNames from "classnames";
import loadable from "@loadable/component";

const PageContainer = loadable(() => import("@layout/DefaultPageContainer"));
const Panels = loadable(() => import("@organisms/Panels"));
const Hero = loadable(() => import("@organisms/Hero"));

const panels = [
  {
    heading: "The Meta Financial View",
    subheading: "Align your lifestyle with what you value.",
    copy: `<p>Whatever your age or income, I can help you define the life you want to lead and how to set yourself up to live that life.</p>
    <p>Go from not having a clear financial plan that supports your values, lifestyle, and future dreams, to making choices that support what’s important to you.</p> 
    <p>The decisions are all up to you. My role is to help you to see the broader horizon so that you can make confident, informed choices. This allows you to feel more present and aware in your day-to-day life while also tending to your future.</p>`,
    icon: "lighthouse",
  },
  {
    heading: "Own Your Life",
    subheading: "Get a clear view of the big picture.",
    copy: `<p>The number of plates everyday people juggle can be staggering. Income. Credit cards. Investments. Bills. School loans. Savings. Rent. Car loans. Business expenses. Mortgages. Retirement accounts. It can be so overpowering that you sometimes don’t want to look at all the details.</p>
    <p>I act as an ally to talk it all through and put the pieces of your financial puzzle together — someone who has the knowledge, patience, and sense of humor to make the process feel interesting and lighter.</p>
    `,
    icon: "sand_dollars",
    image: "road_low",
  },
  {
    heading: "Gather Your Team",
    subheading: "Build and tap into a financial support system.",
    copy: `<p>If you think a financial team is just for billionaires, think again. Lots of regular people have access to CPAs, Investment Advisors, Insurance Brokers, HR Managers, Attorneys, Trusted Family Members, Teachers, and other Voices of Wisdom they respect. That’s your team.</p> 
    <p>Together, we can figure out who should be on your team, when to add new teammates, how to find right-fit people, and what you need to ask them so that you can wrap your head around your financial life.</p>`,
    icon: "fish",
    // image: "pad",
    inverse: false,
  },
  {
    heading: "Pull It All Together",
    subheading: "Develop a holistic financial strategy.",
    copy: `<p>Once you’ve talked to your financial team, we’ll discuss how you can use their input to create a financial lifestyle strategy that works with your unique values, desires, and resources.</p> 
    <p>The ultimate goal is to reach a place where you have a firm grasp of your day-to-day, near term, and long-term finances. It’s a state where you are able to put your plan in motion, sit back, and enjoy the journey for months at a time.</p>`,
    icon: "anchor",
    image: "put_it_all_together",
  },
  {
    heading: "Navigate Life’s Transitions",
    subheading: "Move forward with support.",
    copy: `<p>Your needs and priorities change at different points in your life. Maybe you just settled into your career, finished college, got married, started a family, launched a business, became an empty nester, started contemplating retirement, or hit an unexpected road bump.</p>
    <p>Each transition requires that you harken back to your overarching values and iterate on your financial lifestyle strategy. I can help you re-orient, discuss options, get centered, and choose a way forward that aligns with your core being.</p>`,
    icon: "compass",
    inverse: false,
  },
  {
    heading: "As the Crow Flies",
    subheading: "I’m located in Santa Cruz, CA.",
    copy: `<p>Mountains. Ocean. World-famous boardwalk. Vampires. A close neighbor to Silicon Valley and San Francisco. It’s an awesome place to live and work.</p>
    <p>Due to the benefits of technology, I’m able to work with people close to my home and across the country.</p>
    <p>Set up an appointment to chat about working together.</p>`,
    icon: "compass_rose",
    buttons: [
      {
        text: "Make Appointment",
        url: "mailto:lisa@lisabenson.net",
      },
    ],
    image: "arch",
  },
  {
    heading: "An Outside Perspective",
    subheading: "I support people without having my own agenda.",
    copy: `<p>I have an eclectic background that allows me to work with a lot of different kinds of people — ingenious entrepreneurs, creative artists, young people just starting careers, people who want to change direction, and established people who want to align their spending with their values, just to name a few.</p>
    <p>I’m someone who sees the big picture and digs into details. My perspective on finances is pragmatic and esoteric while being grounded in decades of experience helping individuals and business owners create a financial lifestyle strategy that helps them lead a life based on what matters to them most.
    </p>`,
    // image: "an_outside_perspective",
    inverse: false,
    bottomImage: "lisa",
  },
  {
    heading: "What People Say",
    subheading: "The people I’ve worked with tell my story best.",
    copy: `<p>I love helping people create the life they want to live. If you’re thinking about working with me, it might be helpful for you to hear from some of them.</p>`,
    testimonials: [
      {
        quote:
          "<p>Working with Lisa is an absolute joy! Our conversations always left me feeling inspired, with a list of clear action items for the near and far term. She has a wealth of knowledge and wisdom, and gave me so much to consider as I strive to align my finances with my personal and professional goals.</p>",
        citation: "Bettina Mueller-Tuescher, Theatre Professional",
      },
      {
        quote: `<p>I started working with Lisa after a messed up couple of years. Newly divorced & recently moved to a different state in the middle of the pandemic, I’d been avoiding all the scary stuff...</p>
          <p>Through a series of sessions, she helped me look at what was working and what wasn’t. She asked questions that would help me untangle not only my finances but the fears & emotions around it all.</p>
          <p>With her knowledge, kindness and humor, she pushed me through the hard parts and I am on the other side. She approaches The Big Picture in a holistic way -  I now know what I have, what I need to save, and most importantly, that I will be ok in my retirement.</p>
          <p>I strongly recommend working with Lisa</p>
          `,
        citation:
          "Elissa Kline, Photographer/Photo Archivist /Social Media Manager Carole King Productions",
      },
      {
        quote:
          "<p>Lisa asks the most thought provoking and insightful questions that get to the core of what's important. I feel like I have a plan to bring my current financial life and my broader vision and goals into line with each other.</p>",
        citation: "Harley Eblen, Musician",
      },
    ],
  },
];

const meta = {
  title: "Lisa Benson: Financial Life Strategist",
  metaTitle: "Lisa Benson: Financial Life Strategist",
  metaDescription:
    "Whatever your age or income, I can help you define the life you want to lead and how to set yourself up to live that life. Go from not having a clear financial plan that supports your values, lifestyle, and future dreams, to making choices that support what’s important to you.",
  metaImage:
    "https://lisabenson.imgix.net/lisa_benson_life_strategist_hero.jpg",
  url: "https://lisabenson.net/",
  slug: "life-strategist",
};

const Home = () => {
  return (
    <PageContainer meta={meta}>
      <Hero
        subheading="Financial Life Strategist"
        tagline={`<span>
        Bored with what is.
        <br />
        Focused on what can be.
      </span>`}
        image="lisa_benson_life_strategist_hero"
        path="/"
      />
      <Panels panels={panels} />
    </PageContainer>
  );
};

export default Home;
